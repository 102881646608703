<template>
  <svg :width="size" :height="size" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>arrow</title>
    <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect id="path" x="0" y="0" width="15" height="15"></rect>
      <polygon id="Path" fill="#19191C" opacity="0.8" transform="translate(7.500000, 7.500000) rotate(-180.000000) translate(-7.500000, -7.500000) " points="10.5600533 9.75 7.5 6.96864204 4.44 9.75 3.5 8.89376404 7.5 5.25 11.5 8.89376404"></polygon>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconArrow',
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    size: {
      type: [String, Number],
      default: 18
    }
  }
}
</script>